import React, { useCallback, useEffect, useRef, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OwlCarousel from "react-owl-carousel";

import Innerheader from "../../../components/Innerheader";
import Sidebar from "../../../components/Sidebar";
import TemplateDetailsModal from "../../../components/TemplateDetailsModal";
import ShareModal from "../../../components/ShareModal";
import {
  getCategoriesDetails,
  getCategoryTemplate,
  getKeyword,
  getSimilarTemplate,
} from "../../../api/apiHandler";
import { getToken } from "../../../Common/LocalStorageService";
import Header from "../../../components/Header";
import leftImage from "../../../Common/left-white.svg";
import rightImage from "../../../Common/right-white.svg";
import Lottie from "react-lottie";
import loadingAnimation from "../../Editor/loder.json";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";

import { Navigation, FreeMode } from "swiper/modules";

const VideoComponent = ({
  item,
  setShareId,
  setIsShareModalOpen,
  editTemplate,
  userinfo,
  setTemplateData,
  setIsTemplateDetailsModalOpen,
}) => {
  const videoRef = useRef(null);

  // Event handlers for hover
  const handleMouseEnter = (e) => {
    console.log("handleMouseEnter: ");
    if (videoRef && videoRef.current) {
      // setIsPlaying(index);
      videoRef.current.play(); // Start playing the video
    }
  };

  const handleMouseLeave = (e) => {
    console.log("handleMouseEnter: 55664564");
    if (videoRef && videoRef.current) {
      // setIsPlaying(-1);
      videoRef.current.pause(); // Pause the video
    }
  };
  useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.load(); // Ensure video is loaded
    }
  }, [item?.video_url]);
  console.log(
    "item: ====> ",
    item,
    item?.is_video == 1 && item?.video_url.length > 0
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <li key={"show" + item.id} style={{ position: "relative" }}>
      {item?.is_video == 1 && item?.video_url.length > 0 && (
        <a className="video_show2">
          {console.log("item123.: ", item)}
          <img
            src={process.env.PUBLIC_URL + "/assets/images/icon/video-pause.svg"}
          />
        </a>
      )}
      <div
        className="overlay_hover"
        onMouseEnter={(e) => {
          handleMouseEnter(e);
        }}
        onMouseLeave={(e) => {
          handleMouseLeave(e);
        }}
        onClick={(e) => {
          e.stopPropagation();
          setTemplateData(item);
          setIsTemplateDetailsModalOpen(true);
        }}
      >
        <a className="template_image ">
          {/* <img src={item?.thumbnail} /> */}
          {item?.is_video == 1 && item?.video_url ? (
            <video
              src={item?.video_url}
              ref={videoRef}
              loop
              muted
              playsInline
              preload="auto"
              style={{ objectFit: "contain", height: "100%" }}
            // , width: 'auto'
            ></video>
          ) : (
            <img src={item?.thumbnail} />
          )}
        </a>
        <div className="overlay_div">
          <div className="top_li">
            <a
              onClick={(e) => {
                e.stopPropagation();
                setShareId(item?.id);
                setIsShareModalOpen(true);
              }}
            >
              <img src={process.env.PUBLIC_URL + "/assets/images/share.svg"} />
            </a>
            <a
              onClick={(e) => {
                e.stopPropagation();
                editTemplate(item);
              }}
            >
              <img src={process.env.PUBLIC_URL + "/assets/images/edit.svg"} />
            </a>
          </div>
          <div className="bottom_li">
            <div
              className="user_name cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                userinfo(item?.user_id);
              }}
            >
              {/* <img src={item?.profile_image} /> */}
              {/* <h6 className="user-name-h6">{item?.full_name}</h6> */}
              {/* <h6>{item?.full_name?.length > 6 ? `${item.full_name.substring(0, 6)}...` : item.full_name}</h6> */}
            </div>
            {/* <a
              onClick={() => {
                setTemplateData(item);
                setIsTemplateDetailsModalOpen(true);
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/info.svg"
                }
                className="infoImg"
              />
            </a> */}
          </div>
        </div>
      </div>
    </li>
  );
};
const TemplateListing = (props) => {
  const { isSimilarTemplate = false } = props;
  const navigate = useNavigate();
  const { category, subcategory, userId } = useParams();
  const [indexdata, setIndexData] = useState();
  const [categoryId, setCategoryId] = useState(0);

  const { t, i18n } = useTranslation();
  const [isLogin, setIsLogin] = useState(false);
  const [categoryTemplate, setcategoryTemplate] = useState([]);
  const [isTemplateDetailsModalOpen, setIsTemplateDetailsModalOpen] =
    useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isKeywordClick, setIsKeywordClick] = useState(false);
  const [shareId, setShareId] = useState();
  const [templateData, setTemplateData] = useState({});
  const [keyword, setkeyword] = useState([]);
  const userinfo = useCallback(
    (id) => {
      navigate(`/designer-profile/${id}`);
    },
    [navigate]
  );
  const editTemplate = useCallback(
    (data) => {
      if (getToken()) {
        navigate(`/copy-template/${data?.id}`);
      } else {
        setIsTemplateDetailsModalOpen(false);
        setIsLogin(true);
      }
    },
    [navigate, setIsLogin, setIsTemplateDetailsModalOpen]
  );
  const handletemplate = useCallback(
    (keyword_id, index) => {
      setIndexData(index);
      getCategoryTemplate({ id: categoryId, userId, keyword_id: keyword_id }).then(
        (response) => {
          if (response.code === 200) {
            setIsKeywordClick(false);
            setcategoryTemplate(response?.data[0]);
          }
        }
      );
    },
    [categoryId, userId]
  );
  const handleCategoryTemplate = useCallback(() => {
    getCategoryTemplate({ id: categoryId, userId }).then((response) => {
      console.log("response: ", response);
      if (response.code === 200) {
        setIsKeywordClick(false);
        setcategoryTemplate(response?.data[0]);
      }
    });
  }, [categoryId, userId]);
  useEffect(() => {
    if (isSimilarTemplate) {
      getSimilarTemplate({ template_id: categoryId }).then((response) => {
        if (response.code === 200) {
          setcategoryTemplate({ templates: response.data });
        }
      });
    } else {
      handleCategoryTemplate();
      // getCategoryTemplate({ id, userId }).then((response) => {
      //   console.log('response: ', response);
      //   if (response.code === 200) {
      //     setcategoryTemplate(response?.data[0]);
      //   }
      // });
    }
  }, [handleCategoryTemplate, categoryId, isSimilarTemplate]);

  useEffect(() => {
    getCategoriesDetails({ slug: subcategory }).then((response) => {
      console.log('response7777: ', response);
      if (response.code === 200) {
        setCategoryId(response?.data?.id);
      } else {
        setCategoryId(0);
      }
    });
  }, [subcategory])

  useEffect(() => {
    getKeyword({ theme_id: categoryId }).then((response) => {
      if (response.code === 200) {
        setkeyword(response?.data);
      } else {
        setkeyword([]);
      }
    });
  }, []);

  return (
    <main>
      {getToken() ? (
        <Innerheader />
      ) : (
        <Header openLogin={isLogin} setOpenLogin={setIsLogin} />
      )}
      <div
        className={getToken() ? "wrapper" : ""}
        style={{ minHeight: "80vh" }}
      >
        {getToken() && <Sidebar />}
        {isTemplateDetailsModalOpen && (
          <TemplateDetailsModal
            data={templateData || {}}
            setTemplateData={setTemplateData}
            isTemplateDetailsModalOpen={isTemplateDetailsModalOpen}
            setIsTemplateDetailsModalOpen={setIsTemplateDetailsModalOpen}
            setIsShareModalOpen={setIsShareModalOpen}
            setIsLogin={setIsLogin}
            setShareId={setShareId}
          />
        )}

        {isShareModalOpen && (
          <ShareModal
            shareId={shareId}
            isShareModalOpen={isShareModalOpen}
            setIsShareModalOpen={setIsShareModalOpen}
          />
        )}

        <div
          className={`content flex-grow-1 ${getToken() ? "" : "container"} `}
        >
          <section className="category_main_title">
            <div className="">
              {categoryTemplate?.banner_image && (
                <img
                  className="mobile_hide"
                  src={categoryTemplate?.banner_image}
                  style={{ width: "100%", maxHeight: "25vh" }}
                  alt=""
                ></img>
              )}
              <p className="mt-3 mb-3">
                <span
                  onClick={() => {
                    navigate("/home");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Home{" "}{">"}{" "}
                </span>
                <span
                  onClick={() => {
                    navigate("/home", { state: { category_id: categoryTemplate?.subcategory_id } });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {categoryTemplate?.main_category_name}{" "}
                </span>{" "}
                {">"}{" "}
                {/* <span
                  onClick={() => {
                    navigate("/home");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {categoryTemplate?.name}{" "}
                </span> */}
                <b>{categoryTemplate?.name}</b>
              </p>
            </div>
            <section className="InstagramPostSection checkkk">
              <div className="container-fluid w-100">
                <div className="row">
                  <div className="col-12">
                    <Swiper
                      modules={[Navigation, FreeMode]}
                      slidesPerView={"auto"}
                      spaceBetween={10}
                      navigation={true}
                      onSlideChange={() => console.log("slide change")}
                      onSwiper={(swiper) => console.log(swiper)}
                      className="mySwiper"
                    >
                      {categoryTemplate?.keywords?.map((item, index) => {
                        return (
                          <SwiperSlide
                            className="InstagramPostItem w-auto"
                            key={"keyword" + item.keyword_id}
                            onClick={() => {
                              setIsKeywordClick(true);
                              if (indexdata == index) {
                                setIndexData();
                                handleCategoryTemplate();
                              } else {
                                handletemplate(item?.keyword_id, index);
                              }
                            }}
                          >
                            <a
                              href="javascript:void(0)"
                              className="InstagramPostItemLink"
                              style={{
                                fontWeight: indexdata == index ? "700" : "500",
                              }}
                            >
                              {item.name}
                            </a>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </div>
              </div>
            </section>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 mb-4 d-flex justify-content-between align-items-center flex-wrap"></div>
                <div className="col-12">
                  <div className="row">
                    <div
                      class={`col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4 ${isKeywordClick && "d-flex justify-content-center"
                        }`}
                    >
                      {isKeywordClick ? (
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/loaderOld.gif"
                          }
                          height={50}
                          alt=""
                        ></img>
                      ) : (
                        <div
                          class={`ul_list position-relative ${categoryTemplate?.templates?.length > 0 &&
                            categoryTemplate?.templates[0].orientation ==
                            "portrait"
                            ? "three_items"
                            : categoryTemplate?.templates?.length > 0 &&
                              categoryTemplate?.templates[0].orientation ==
                              "landscape"
                              ? ""
                              : "two_items"
                            }`}
                        >
                          {categoryTemplate?.templates?.length > 0 ? (
                            categoryTemplate?.templates?.map((item) => {
                              return (
                                // <li key={"view" + item.id}>
                                <VideoComponent
                                  item={item}
                                  setShareId={setShareId}
                                  setIsShareModalOpen={setIsShareModalOpen}
                                  editTemplate={editTemplate}
                                  userinfo={userinfo}
                                  setTemplateData={setTemplateData}
                                  setIsTemplateDetailsModalOpen={
                                    setIsTemplateDetailsModalOpen
                                  }
                                />
                                // </li>
                              );
                            })
                          ) : (
                            <div
                              style={{ height: "100px" }}
                              className="fw-light d-flex justify-content-center align-items-center  w-100"
                            >
                              {t("noDataFound")}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
};

export default TemplateListing;
