import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setlanguage } from "../store/slice/Changelanguage";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import checkValidation from "../validation/ValidationSchema";
import { sunscribeNewsLetter } from "../api/apiHandler";
import { showPromiseNotification } from "../Common/notification";

const Footer = (props) => {
  const { isLandingPage = false } = props;

  const dispatch = useDispatch();
  const changelanguage = useSelector((state) => state.changelanguage);
  const [language, setLanguage] = useState(
    changelanguage != null ? changelanguage : "EN"
  );
  console.log("language: ", language);
  const { t, i18n } = useTranslation();
  const handleLogoClick = () => {
    console.log("kjkjfkdjfkdfjk");

    window.scrollTo(0, 0);
    // .scrollIntoView({
    //   behavior: "smooth",
    //   block: "start",
    // });
  };
  const schema = checkValidation(["Email"]);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    clearErrors,
    setError,
    setValue,
    watch,
    reset,
    formState: { isSubmitted },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
    dispatch(setlanguage(lng));
    localStorage.setItem("selectedLanguage", lng);
  };

  const onSubmit = (data) => {
    const payload = { email: data?.Email.toLowerCase() };

    const myPromise = new Promise((resolve, reject) =>
      sunscribeNewsLetter(payload).then((response) => {
        if (response.code === 200) {
          reset();
          resolve(response);
        } else {
          reject(response);
        }
      })
    );
    showPromiseNotification(myPromise);
  };

  return (
    <div>
      <>
        {isLandingPage && (
          <section className="subscribe_section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="subscribe_plan">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="subscribe_text">
                          <h4>{t("subscribeText")}</h4>
                          <h4>{t("subscribeSubText")}</h4>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="subscribe_form">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <input
                              type="text"
                              className="subscribe_input"
                              placeholder={t("Emailaddress")}
                              {...register("Email")}
                            />

                            <button type="submit">{t("joinButton")}</button>
                          </form>
                        </div>
                        {errors.Email && (
                          <div className="text-danger news-letter-error">
                            {" "}
                            {errors.Email.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        <footer className="footer">
          <div className="container">
            <div className="row" style={{ textAlign: 'left' }}>
              <div className="col-lg-3">
                <div className="footer_about">
                  <div className="footer-logo">
                    <Link
                      to="/"
                      id="brand-logo"
                      className="nav-brand d-block "
                      onClick={() => {
                        handleLogoClick();
                      }}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/Craetivo_logo_white.png"
                        }
                      />
                    </Link>
                  </div>
                  <p>{t("footerAddress")}</p>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="row" >
                  <div className="col-md-4 col-sm-6 col-6 mb-3">
                    <ul className="footer-link">
                      <li>
                        <Link to="/home">{t("footerHome")}</Link>
                      </li>
                      <li>
                        <Link to="/about-us">{t("footerAbout")}</Link>
                      </li>
                      <li>
                        <Link to="/faqs">{t("footerFAQ")}</Link>
                      </li>
                      <li>
                        <Link to="/contact-us">{t("footerContact")}</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4 col-sm-6 col-6 mb-3">
                    <ul className="footer-link">
                      <li>
                        <Link to="/terms-conditions">{t("footerTerms")}</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">{t("footerPrivacy")}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copy_right">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-6 mb-3 mb-sm-0">
                  <p>{t("footerCopyright")}</p>
                </div>
                <div className="col-sm-6 col-6 mb-3 mb-sm-0 text-end">
                  <div className="multiple_lang ">
                    <div className="dropdown">
                      <button
                        className="dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}${language === "EN"
                            ? "/assets/images/en.svg"
                            : language == "FR"
                              ? "/assets/images/FR.svg"
                              : "/assets/images/PR.svg"
                            }`}
                        />{" "}
                        {language}
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => changeLanguage("EN")}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL + "/assets/images/en.svg"
                              }
                            />{" "}
                            {t("English")}
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => changeLanguage("FR")}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL + "/assets/images/FR.svg"
                              }
                            />{" "}
                            {t("French")}
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => changeLanguage("PT")}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL + "/assets/images/PR.svg"
                              }
                            />{" "}
                            {t("Portuguese")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    </div>
  );
};

export default Footer;
