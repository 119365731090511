import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import checkValidation from "../../../validation/ValidationSchema";
import { useTranslation } from "react-i18next";
// import Signup from "./Signup";
import { signup } from "../../../api/apiHandler";
import { showPromiseNotification } from "../../../Common/notification";
import { Link } from "react-router-dom";

const SignupModal = (props) => {
  const {
    isSignup,
    closeSignupModal,
    setSuccessSignUp,
    openDashboardModal,
    openLoginModal,
    state = ""
  } = props;

  const { t, i18n } = useTranslation();
  const schema = checkValidation([
    "Fullname",
    "Email",
    // "PhoneNumber",
    // "DateOfBirth",
    "Password",
    "Confirm_Password",
    "AcceptTerms",
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
    setValue,
    watch,
    formState: { isSubmitted },
  } = useForm({
    resolver: yupResolver(schema),
  });

  console.log("errors: ", errors);
  const onSubmit = (data) => {
    // const phoneNumber = phoneUtil.parseAndKeepRawInput(data?.PhoneNumber);
    const payload = {
      full_name: data?.Fullname,
      email: data?.Email,
      // country_code: `+${phoneNumber.getCountryCode()}`,
      // phone: phoneNumber.getNationalNumber().toString(),
      // dob: moment(data?.DateOfBirth).format(),
      password: data?.Password,
      device_name: "website",
      device_token: "-",
      device_type: "W",
    };

    const myPromise = new Promise((resolve, reject) =>
      signup(payload).then((response) => {
        console.log("response: ", typeof response, response);
        if (response.code === 200) {
          setSuccessSignUp(response?.data);
          openDashboardModal();
          resolve(response);
        } else {
          reject(response);
        }
      })
    );
    showPromiseNotification(myPromise);
  };

  return (
    <>
      <div
        className={`modal fade ${isSignup ? "show" : ""}`}
        id="signUp-modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        style={{ display: isSignup ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="custom_btn signupCloseBtn"
                onClick={(e) => {
                  e.stopPropagation();
                  closeSignupModal();
                }}
                aria-label="Close"
              >
                <img
                  className="WhiteBtn"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/close-white.svg"
                  }
                  alt="Close"
                />
                <img className="BlackBtn" src="/assets/images/close.svg" alt="Close"></img>
              </button>
              {/* <Signup /> */}
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="modal-signup-left">
                    <div className="modal-logo">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/Logosvg1.svg"
                        }
                        className="modal-logo-img"
                      />
                    </div>
                    <h6 className="modal-heading">{t("createFreeAccount")}</h6>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <ul className="link-box-ul">
                        {/* <li className="link-box-li">
                          <a href="#" className="google-link">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icon/google-icon.svg"
                              }
                              alt="google-icon"
                              className="login-modal-icon"
                            />
                            {t("continueWithGoogle")}
                          </a>
                        </li>
                        <li className="link-box-li">
                          <a href="#" className="facebook-link">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icon/fb-icon.svg"
                              }
                              alt="facebook-icon"
                              className="login-modal-icon"
                            />
                            {t("continueWithFB")}
                          </a>
                        </li>
                        <li className="link-box-li">
                          <div className="or-box">
                            <p className="continue-or-text">OR</p>
                          </div>
                        </li> */}
                        <li className="link-box-li">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("fullName")}
                            {...register("Fullname")}
                          />
                          {errors.Fullname && (
                            <span class="text-danger">
                              {" "}
                              {errors.Fullname.message}
                            </span>
                          )}
                        </li>
                        <li className="link-box-li">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("email")}
                            {...register("Email")}
                          />
                          {errors.Email && (
                            <span class="text-danger">
                              {" "}
                              {errors.Email.message}
                            </span>
                          )}
                        </li>
                        <li className="link-box-li">
                          <input
                            type={"password"}
                            className="form-control"
                            placeholder={t("password")}
                            {...register("Password")}
                          />
                          {errors.Password && (
                            <span class="text-danger">
                              {" "}
                              {errors.Password.message}
                            </span>
                          )}
                        </li>
                        <li className="link-box-li">
                          <input
                            type={"password"}
                            className="form-control"
                            placeholder={t("confirmPassword")}
                            {...register("Confirm_Password")}
                          />
                          {errors.Confirm_Password && (
                            <span class="text-danger">
                              {" "}
                              {errors.Confirm_Password.message}
                            </span>
                          )}
                        </li>
                        <li className="link-box-li">
                          <div className="form-check check_mark">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              {...register("AcceptTerms")}
                              id="flexCheckDefault"
                            />
                            <label
                              className="form-check-label m-0"
                              htmlFor="flexCheckDefault"
                            >
                              {t("agreeTerms")}{" "}
                              <Link
                                to={"/terms-conditions"}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {t("termsOfService")}
                              </Link>{" "}
                              {t("and")}{" "}
                              <Link
                                to={"/privacy-policy"}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {t("privacyPolicy")}.
                              </Link>
                            </label>
                          </div>
                          {errors.AcceptTerms && (
                            <span class="text-danger">
                              {" "}
                              {errors.AcceptTerms.message}
                            </span>
                          )}
                        </li>
                        <li className="link-box-li">
                          <div className="form-group left_fixed">
                            <button type="submit" className="btn btn-theme">
                              {t("signupButton")}
                            </button>
                          </div>
                        </li>

                        <li className="link-box-li">
                          <p className="dont-text mt-0">
                            {t("alreadyHaveAccount")}{" "}
                            <span
                              onClick={() => {
                                openLoginModal();
                              }}
                              className="sign-up-link cursor-pointer d-inline-block"
                            >
                              {t("signIn")}
                            </span>
                          </p>
                        </li>
                      </ul>
                    </form>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="modal-signup-right">
                    <h3 className="modal-right-heading">
                      {t("signUpHeading")}
                    </h3>
                    <ul className="modal-right-ul">
                      <li className="modal-right-li">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/check-white-img.png"
                          }
                          alt="check-white-img"
                          className="check-white-img"
                        />
                        {t("unlimitedAccess")}
                      </li>
                      <li className="modal-right-li">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/check-white-img.png"
                          }
                          alt="check-white-img"
                          className="check-white-img"
                        />
                        {t("experienceNotRequired")}
                      </li>
                      <li className="modal-right-li">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/check-white-img.png"
                          }
                          alt="check-white-img"
                          className="check-white-img"
                        />
                        {t("backgroundRemoval")}
                      </li>
                      <li className="modal-right-li">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/check-white-img.png"
                          }
                          alt="check-white-img"
                          className="check-white-img"
                        />
                        {t("uploadOwnFonts")}
                      </li>
                    </ul>
                    <div className="modal-right-img-box">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/sign-right-banner.png"
                        }
                        alt="sign-right-banner "
                        className="sign-right-banner"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </form> */}
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default SignupModal;
