import React, { useEffect, useRef } from 'react'
function VideoPlay({ item, index, editTemplate, setShareId, setIsShareModalOpen, setIsTemplateDetailsModalOpen, userinfo, setTemplateData }) {
    const videoRef = useRef(null);

    // Event handlers for hover
    const handleMouseEnter = (e, index) => {
        if (videoRef && videoRef.current) {
            // setIsPlaying(index);
            videoRef.current.play(); // Start playing the video
        }
    };

    const handleMouseLeave = (e, index) => {
        if (videoRef && videoRef.current) {
            // setIsPlaying(-1);
            videoRef.current.pause(); // Pause the video
        }
    };
    // const handleIntersection = (entries) => {
    //     entries.forEach((entry) => {
    //         if (videoRef.current && entry.isIntersecting) {
    //             // Video is in view, autoplay
    //             videoRef.current.play();
    //         } else if (videoRef.current) {
    //             // Video is not in view, pause
    //             videoRef.current.pause();
    //         }
    //     });
    // };
    const togglePlayback = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play(); // Play the video if paused
            } else {
                videoRef.current.pause(); // Pause the video if playing
            }
        }
    };
    useEffect(() => {
        if (videoRef && videoRef.current) {
            videoRef.current.load(); // Ensure video is loaded
        }
    }, [item?.video_url]);
    return (
        <div
            key={"template_in" + item?.name + "_" + index}
            className={`item ${item?.orientation == "landscape"
                ? "landscapeWidth"
                : "templateWidth"
                }`}
            style={{ padding: "0 3%", position: "relative" }}
            onMouseEnter={(e) => { handleMouseEnter(e, index) }}
            onMouseLeave={(e) => { handleMouseLeave(e, index) }}
        >
            {item?.is_video == 1 && (
                <a className='video_show' onClick={(e) => { e.stopPropagation(); togglePlayback() }}>
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icon/video-pause.svg"
                        }
                    />
                </a>
            )}
            <div className="overlay_hover"
                onClick={(e) => {
                    e.stopPropagation();
                    setTemplateData(item);
                    setIsTemplateDetailsModalOpen(true);
                }}
            >
                <a className="template_image_slider ">
                    {/* position-relative */}
                    {item?.is_video == 1 && item?.video_url ? (
                        // <VideoPlay item={item} />
                        <video
                            src={item?.video_url}
                            ref={videoRef}
                            loop
                            muted
                            playsInline
                            preload="auto"
                            style={{
                                objectFit: "contain",
                                height: "100%",
                                width: "100%",
                            }}
                        ></video>
                    ) : (
                        <img src={item?.thumbnail} />
                    )}
                </a>
                <div className="overlay_div">
                    <div
                        className={`d-flex justify-content-end`}
                    >

                        <div className="top_li">
                            <a
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShareId(item?.id);
                                    setIsShareModalOpen(true);
                                }}
                            >
                                <img
                                    src={process.env.PUBLIC_URL + "/assets/images/share.svg"}
                                />
                            </a>
                            <a
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editTemplate(item);
                                }}
                            >
                                <img
                                    src={process.env.PUBLIC_URL + "/assets/images/edit.svg"}
                                />
                            </a>
                        </div>
                    </div>
                    <div className="bottom_li">
                        {/* <div
                            className="user_name cursor-pointer"
                            onClick={(e) => { e.stopPropagation(); userinfo(item?.user_id) }}
                        >
                            <img src={item?.profile_image} />
                            <h6>{item?.full_name}</h6>
                            <h6 className="user-name-h6">
                                {item?.full_name}
                            </h6>
                        </div> */}
                        {/* <a
                            onClick={() => {
                                setTemplateData(item);
                                setIsTemplateDetailsModalOpen(true);
                            }}
                        >
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/info.svg"}
                                className="infoImg"
                            />
                        </a> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoPlay