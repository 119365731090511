import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import {
  addTemplate,
  getAllCategories,
  getKeyword,
  getTemplate,
  getTheme,
  publishTemplate,
} from "../../../api/apiHandler";
import { TagsInput } from "react-tag-input-component";
import uploadToS3, {
  generateRandomFileName,
  uploadOrUpadateJSONToS3,
} from "../../../Common/s3AwsService";
import {
  showErrorMessage,
  showPromiseNotification,
} from "../../../Common/notification";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import Lottie from "react-lottie";
import loadingAnimation from "../loder.json";
import Multiselect from "multiselect-react-dropdown";
window.jQuery = window.$ = $;
require("jquery-nice-select");

const PublishTemplate = (props) => {
  const {
    isPublishTemplateModalOpen,
    setIsPublishTemplateModalOpen,
    store,
    submitBackClick,
    templateData,
    setIsPublish,
    setIsPublishGoing,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [category, setCategory] = useState([]);
  const [theme, setTheme] = useState([]);
  const [keyword, setkeyword] = useState([]);
  console.log("keyword: ", keyword);
  const [subcategory, setSubcategory] = useState([]);
  const [templateDetails, setTemplateDetails] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  console.log("category: ", category);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const validationSchema = Yup.object().shape({
    // name: Yup.string().required("Name is required"),
    orientation: Yup.string().required("Orientation is required"),
    // colors: Yup.array()
    //   .of(
    //     Yup.object().shape({
    //       color: Yup.string().required(), // Validation for the color field
    //     })
    //   )
    //   .min(1, "At least one color is required"),
    category: Yup.string().required("Category is required"),
    subcategory: Yup.string().required("Sub Category is required"),
    theme: Yup.string().required("Theme is required"),
    keywords: Yup.array()
      .of(Yup.string().required("Each keyword is required"))
      .min(1, "At least one keyword is required") // Ensure at least one keyword is present
      .max(3, "A maximum of 3 keywords are allowed")
      .required("Keywords are required"),
    // keyword: Yup.array()
    //   .of(Yup.string().required())
    //   .min(1, "At least one keyword is required"),
    // .max(3, "At least one keyword is required"),
    // keywords: Yup.array()
    //   .of(Yup.string().required())
    //   .min(1, "At least one keyword is required"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      colors: [],
    },
  });
  console.log("watch: ", watch());

  console.log("errors: ", errors);

  useEffect(() => {
    $(document).ready(function () {
      $("select").niceSelect();
    });
  }, [setValue]);

  const colors = [
    "#000000",
    "#FFFFFF",
    "#C0C0C0",
    "#808080",
    "#800000",
    "#FF0000",
    "#800080",
    "#FF00FF",
    "#008000",
    "#00FF00",
    "#808000",
    "#FFFF00",
    "#000080",
    "#0000FF",
    "#008080",
    "#00FFFF",
  ];

  const getSubCategory = useCallback((id) => {
    getAllCategories({ category_id: id }).then((response) => {
      console.log("response: cateeee", response);
      if (response.code === 200) {
        setSubcategory(response?.data);
      } else {
        setSubcategory([]);
        showErrorMessage(response?.message);
      }
    });
  }, []);
  const handleSelect = useCallback(
    (selectedList, selectedItem) => {
      const ids = selectedList.map((item) => item.id);
      const names = selectedList.map((item) => item.name);
      setValue("keywords", ids);
      setValue("keyword_names", names);
    },
    [setValue]
  );
  const handleRemove = useCallback(
    (selectedList, selectedItem) => {
      const ids = selectedList.map((item) => item.id);
      const names = selectedList.map((item) => item.name);
      setValue("keywords", ids);
      setValue("keyword_names", names);
    },
    [setValue]
  );

  const handleColorChange = (color) => {
    const isColorSelected = watch("colors").some((c) => c.color === color);
    if (isColorSelected) {
      // If the color is already selected, remove it
      setValue(
        "colors",
        watch("colors").filter((c) => c.color !== color)
      );
    } else {
      // If the color is not selected, add it
      setValue("colors", [...watch("colors"), { color }]);
    }
  };

  const handleSubKeyword = useCallback((id) => {
    getKeyword({ theme_id: id }).then((response) => {
      if (response.code === 200) {
        setkeyword(response?.data);
      } else {
        setkeyword([]);
      }
    });
  }, []);
  const onSubmit = useCallback(
    async (data) => {
      console.log("store====>", store, store.toJSON());
      setIsPublishGoing(true);
      const watermarkIds = [];
      store.toJSON().pages.forEach((page) => {
        const filteredChildren = page
          // .toJSON()
          .children.filter((child) => child.name == "watermark");
        console.log("filteredChildren: ", filteredChildren);

        watermarkIds.push(...filteredChildren?.map(item => item.id));
      });
      store.deleteElements(watermarkIds);
      console.log('watermarkIds: ', watermarkIds, store.toJSON());
      // const updatedStore = {
      //   ...store,
      //   pages: updatedPages,
      // };

      setIsSubmitting(true);
      const { subcategory, category, ...restPayload } = data;
      // handle form submission

      const myPromise = new Promise(async (resolve, reject) => {
        const jsonData = store.toJSON();
        console.log("jsonData: ", jsonData, store.toJSON());
        const fileName = generateRandomFileName();

        const [thumbnailResponse, main_thumbnail, jsonUploadResponse] = await Promise.all([
          store
            .toBlob({ pixelRatio: 0.4, fileName, mimeType: "image/webp", })
            .then((response) => {
              response.name = `${fileName}.${response.type.split("/")[1]}`;
              return uploadToS3(response, response.name, "thumbnails");
            })
            .then((response) => {
              console.log("Thumbnail S3 Key", response.key);
              return response.key;
            })
            .catch((error) => {
              console.error("Thumbnail upload failed:", error);
              throw error;
            }),
          store
            .toBlob({ pixelRatio: 1, fileName, mimeType: "image/webp", })
            .then((response) => {
              response.name = `${"Main" + fileName}.${response.type.split("/")[1]}`;
              return uploadToS3(response, response.name, "thumbnails");
            })
            .then((response) => {
              console.log("Thumbnail S3 Key", response.key);
              return response.key;
            })
            .catch((error) => {
              console.error("Thumbnail upload failed:", error);
              throw error;
            }),
          uploadOrUpadateJSONToS3(jsonData, `${fileName}.json`, "Templates")
            .then((response) => {
              console.log("JSON S3 KEY", response.key);
              return response.key;
            })
            .catch((error) => {
              console.error("JSON upload failed:", error);
              throw error;
            }),
        ]);
        const payload = {
          ...restPayload,
          template_id: templateData.id,
          category_id: category,
          subcategory_id: subcategory,
          template: jsonUploadResponse,
          thumbnail: thumbnailResponse,
          main_thumbnail: main_thumbnail,
          colors: data.colors.map((item) => item.color),
        };

        if (id) {
          payload.main_template_id = id;
        }

        console.log("payloadpublish: ", payload);
        publishTemplate(payload).then((response) => {
          if (response.code === 200) {
            resolve(response);
            setIsPublishTemplateModalOpen(false);
            setIsPublish(false);
            setIsPublishGoing(false);
            if (submitBackClick) {
              navigate("/home");
            } else {
              navigate(`/edit-template/${response.data.id}`);
            }
          } else {
            reject(response);
          }
        });

        showPromiseNotification(myPromise);
      });
    },
    [
      id,
      navigate,
      setIsPublish,
      setIsPublishGoing,
      setIsPublishTemplateModalOpen,
      store,
      submitBackClick,
      templateData.id,
    ]
  );

  useEffect(() => {
    getAllCategories({}).then((response) => {
      console.log("response: cateeee", response);
      if (response.code === 200) {
        setCategory(response?.data);
      } else {
        setCategory([]);
      }
    });
    getTheme({}).then((response) => {
      if (response.code === 200) {
        setTheme(response?.data);
      } else {
        setTheme([]);
      }
    });
    getTemplate({ template_id: id }).then(async (_template) => {
      console.log("_template: ", _template);
      if (_template?.code == 200) {
        setValue("category", _template?.data[0]?.category_id || 1);
        await getSubCategory(_template?.data[0]?.category_id);
        setValue("subcategory", _template?.data[0]?.subcategory_id || 6);
        console.log(store.height, store?.width);
        if (store.height > store?.width) {
          setValue("orientation", "portrait");
        } else if (store.height < store?.width) {
          setValue("orientation", "landscape");
        } else {
          setValue("orientation", "square");
        }
        setTemplateDetails(_template?.data[0]);
      }
    });
  }, []);
  return (
    <>
      <div
        className={`modal fade ${isPublishTemplateModalOpen ? "show d-block" : "d-none"
          }`}
        tabIndex={-1}
        id="add-template-modal"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="custom_btn"
                onClick={() => setIsPublishTemplateModalOpen(false)}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/close.svg"}
                  alt="close"
                />
              </button>
              <div className="modal-login-main">
                <h6 className="modal-heading">{t("publishTemplate")}</h6>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ul className="link-box-ul">
                    {/* <li className="add-template-li">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className={`form-control `}
                      placeholder={t("name")}
                      {...register("name")}
                    />
                    {errors.name && (
                      <span className="text-danger fs-12px">
                        {errors.name.message}
                      </span>
                    )}
                  </li> */}
                    {/* <li className="link-box-li" style={{ display: watch("orientation") ? "none" : "block" }}> */}
                    <li className="link-box-li" style={{ display: "none" }}>
                      <div
                        className={`nice-select `}
                        style={{ width: "100%" }}
                        tabIndex="0"
                      >
                        <span className="">
                          {watch("orientation")
                            ? _.capitalize(watch("orientation"))
                            : t("selectOrientation")}
                        </span>
                        <ul className="list" style={{ zIndex: "1000" }}>
                          <li
                            data-value="landscape"
                            className="option "
                            onClick={() => setValue("orientation", "landscape")}
                          >
                            Landscape
                            {/* {t("Landscape")} */}
                          </li>
                          <li
                            data-value="portrait"
                            className="option "
                            onClick={() => setValue("orientation", "portrait")}
                          >
                            Portrait
                            {/* {t("Portrait")} */}
                          </li>
                          <li
                            data-value="1"
                            className="option "
                            onClick={() => setValue("orientation", "square")}
                          >
                            Square
                            {/* {t("Square")} */}
                          </li>
                          <li
                            data-value="1"
                            className="option "
                            onClick={() => setValue("orientation", "rectangle")}
                          >
                            Rectangle
                            {/* {t("Rectangle")} */}
                          </li>
                        </ul>
                      </div>
                      {!watch("orientation")?.length && errors.orientation && (
                        <span className="text-danger fs-12px ">
                          {errors.orientation.message}
                        </span>
                      )}
                    </li>

                    {/* <div className="select-color">
                    <label className="mb-2">{t("selectColors")}</label>
                    <div className="color_selection">
                      <ul>
                        {colors.map((color) => (
                          <li className="li-white" key={color}>
                            <input
                              type="checkbox"
                              checked={watch("colors").some(
                                (c) => c.color === color
                              )}
                              onChange={() => handleColorChange(color)}
                            />
                            <span
                              className="color-maain"
                              style={{ background: color }}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {errors.colors && (
                    <span className="text-danger fs-12px ">
                      {errors.colors.message}
                    </span>
                  )} */}
                    <li
                      className="link-box-li"
                      style={{ display: watch("category") ? "none" : "block" }}
                    >
                      <div
                        className="nice-select"
                        style={{ width: "100%" }}
                        tabIndex="0"
                      >
                        <span className="">
                          {watch("category")
                            ? category?.find(
                              (item) => item?.id === watch("category")
                            )?.name
                            : t("selectCategory")}
                        </span>
                        <ul className="list">
                          {category?.map((item) => (
                            <li
                              data-value={item?.id}
                              className="option"
                              onClick={() => {
                                setValue("category", item?.id);
                                setValue("subcategory", null);
                                getSubCategory(item?.id);
                              }}
                            >
                              {item?.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                      {!watch("category") && errors.category && (
                        <span className="text-danger fs-12px ">
                          {errors.category.message}
                        </span>
                      )}
                    </li>
                    <li
                      className="link-box-li"
                      style={{
                        display: watch("subcategory") ? "none" : "block",
                      }}
                    >
                      <div
                        className="nice-select"
                        style={{ width: "100%" }}
                        tabIndex="0"
                      >
                        <span className="">
                          {watch("subcategory")
                            ? subcategory?.find(
                              (item) => item?.id === watch("subcategory")
                            )?.name
                            : t("selectSubcategory")}
                        </span>
                        <ul
                          className="list"
                          style={{
                            display: !watch("category") ? "none" : "block",
                          }}
                        >
                          {subcategory?.map((item) => (
                            <li
                              data-value={item?.id}
                              className="option"
                              onClick={() => {
                                setValue("subcategory", item?.id);
                              }}
                            >
                              {item?.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                      {!watch("subcategory") && errors.subcategory && (
                        <span className="text-danger fs-12px ">
                          {errors.subcategory.message}
                        </span>
                      )}
                    </li>
                    <li className="link-box-li">
                      <div
                        className="nice-select"
                        style={{ width: "100%" }}
                        tabIndex="0"
                      >
                        <span className="">
                          {watch("theme")
                            ? theme?.find((item) => item?.id === watch("theme"))
                              ?.name
                            : t("selectTheme")}
                        </span>
                        <ul
                          className="list"
                          style={{ height: "300px", overflow: "auto" }}
                        >
                          {theme?.map((item) => (
                            <li
                              data-value={item?.id}
                              className="option"
                              onClick={() => {
                                setValue("theme", item?.id);
                                handleSubKeyword(item?.id);
                              }}
                            >
                              {item?.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                      {!watch("theme") && errors.theme && (
                        <span className="text-danger fs-12px ">
                          {errors.theme.message}
                        </span>
                      )}
                    </li>
                    <li className="link-box-li">
                      <Multiselect
                        name="keywords"
                        options={keyword}
                        // selectedValues={selectedOptions} // Pre-selected value to persist in dropdown
                        onSelect={handleSelect}
                        onRemove={handleRemove} // Function to call on remove
                        displayValue="name" // Property name to display in the dropdown
                        placeholder={t("selectKeyword")} // Placeholder text for the dropdown
                        disable={keyword.length <= 0}
                      />
                      {errors.keywords && (
                        <span className="text-danger fs-12px ">
                          {errors.keywords.message}
                        </span>
                      )}
                    </li>
                    {/* <li className="add-template-li">
                      <Controller
                        name="keywords"
                        className={"border-0 "}
                        control={control}
                        defaultValue={[]}
                        render={({ field: { onChange, value } }) => (
                          <TagsInput
                            value={value}
                            onChange={onChange}
                            name="keywords"
                            placeHolder={t("enterKeywords")}
                          />
                        )}
                      />
                      {errors.keywords && (
                        <span className="text-danger fs-12px ">
                          {errors.keywords.message}
                        </span>
                      )}
                    </li> */}
                    <li className="link-box-li">
                      <div className="form-group left_fixed">
                        <button
                          type="submit"
                          className="btn btn-theme"
                          disabled={isSubmitting}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {isSubmitting ? (
                            <>
                              {t("pleaseWait")}
                              <Lottie
                                options={defaultOptions}
                                height={25}
                                width={80}
                                style={{ margin: "0" }}
                              />
                            </>
                          ) : (
                            t("publish")
                          )}
                        </button>
                      </div>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPublishTemplateModalOpen && (
        <div className="modal-backdrop fade show"></div>
      )}
    </>
  );
};

export default PublishTemplate;
