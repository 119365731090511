import React, {
  // useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { Toolbar } from "polotno/toolbar/toolbar";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";
import {
  DEFAULT_SECTIONS,
  SectionTab,
  SidePanel,
  VideosSection,
} from "polotno/side-panel";
import { Workspace } from "polotno/canvas/workspace";
import { createStore } from "polotno/model/store";
import { Button, NumericInput } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";
import FaShapes from "@meronex/icons/fa/FaShapes";
// import FaFont from "@meronex/icons/fa/FaFont";
import FaIcons from "@meronex/icons/fa/FaIcons";
import "./star-element";
import "./pen-eliment";
import uploadToS3, {
  uploadOrUpadateJSONToS3,
  fetchJSONFromS3,
  generateRandomFileName,
} from "../../Common/s3AwsService";
import {
  editTemplate,
  getAllFonts,
  getTemplate,
  mp4Download,
} from "../../api/apiHandler";
import { showPromiseNotification } from "../../Common/notification";
import { useLocation, useParams } from "react-router-dom";
import InviteMembers from "./components/InviteMembers";
import AddTemplate from "./components/AddTemplate";
// import CustomFontPanel from "./components/CustomFontPanel";
import { addGlobalFont, setGoogleFonts } from "polotno/config";
import { SocialMediaIconsPanel } from "./components/SocialIconPanel";
import FaBattleNet from "@meronex/icons/fa/FaBattleNet";
import { ShapePanel } from "./components/ShapePanel";
import { ClipartsPanel } from "./components/ClipartsPanel";
import FaStickyNote from "@meronex/icons/fa/FaStickyNote";
import RiUploadCloud2Fill from "@meronex/icons/ri/RiUploadCloud2Fill";
import { StickersPanel } from "./components/StickerPanel";
import { useTranslation } from "react-i18next";
// import { getUserDataFromLocal } from "../../Common/LocalStorageService";
import Header from "./components/Header";
import { PagesTimeline } from "polotno/pages-timeline";
import { QrSection } from "./components/QrSection";
import i18n from "../../i18n";
import { unstable_setAnimationsEnabled } from "polotno/config";
import { QuotesSection } from "./components/QuoteSection";
import ResizeModal from "./components/ResizeModal";
import { setTranslations } from "polotno/config";
import { getTranslations } from "polotno/config";
import { UploadPanel } from "./components/uploadDocuments";
import PublishTemplate from "./components/PublishTemplate";
// import { t } from 'polotno/utils/l10n';
import CustomLoader from "./components/CustomLoder";
import { getUserDataFromLocal } from "../../Common/LocalStorageService";
import { FancyShapePanel } from "./components/FancyShapePanel";
import { Response } from "aws-sdk";

const isStandalone = () => {
  return (
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone
  );
};

const getOffsetHeight = () => {
  let safeAreaInsetBottom = 0;

  if (isStandalone()) {
    // Try to get the safe area inset using env() variables
    const safeAreaInsetBottomString = getComputedStyle(
      document.documentElement
    ).getPropertyValue("env(safe-area-inset-bottom)");
    if (safeAreaInsetBottomString) {
      safeAreaInsetBottom = parseFloat(safeAreaInsetBottomString);
    }

    // Fallback values for specific devices if env() is not supported
    if (!safeAreaInsetBottom) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/iPhone|iPad|iPod/i.test(userAgent) && !window.MSStream) {
        // This is an approximation; you might need to adjust this value based on testing
        safeAreaInsetBottom = 20; // Example fallback value for iPhone
      }
    }
  }

  return window.innerHeight - safeAreaInsetBottom;
};

const useHeight = () => {
  const [height, setHeight] = React.useState(getOffsetHeight());
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setHeight(getOffsetHeight());
    });
  }, []);
  return height;
};

export const FIX_SIZES = [
  {
    category_name: i18n.t("socialMediaGraphics"),
    templates_size: [
      {
        name: i18n.t("instagramPosts"),
        height: 1080,
        width: 1080,
        image: process.env.PUBLIC_URL + "/assets/images/icon/instagram.svg",
      },
      {
        name: i18n.t("instagramStory"),
        height: 1920,
        width: 1080,
        image: process.env.PUBLIC_URL + "/assets/images/icon/instagram.svg",
      },
      {
        name: i18n.t("instagramReels"),
        height: 1920,
        width: 1080,
        image: process.env.PUBLIC_URL + "/assets/images/icon/instagram.svg",
      },
      {
        name: i18n.t("facebookPosts"),
        height: 1200,
        width: 630,
        image: process.env.PUBLIC_URL + "/assets/images/icon/facebook.svg",
      },
      {
        name: i18n.t("facebookCoverPhoto"),
        height: 315,
        width: 820,
        image: process.env.PUBLIC_URL + "/assets/images/icon/facebook.svg",
      },
      {
        name: i18n.t("youtubeCoverPhoto"),
        height: 1440,
        width: 2560,
        image: process.env.PUBLIC_URL + "/assets/images/icon/youtube.svg",
      },
      {
        name: i18n.t("youtubeThumbnail"),
        height: 720,
        width: 1280,
        image: process.env.PUBLIC_URL + "/assets/images/icon/youtube.svg",
      },
      {
        name: i18n.t("twitterBanner"),
        height: 500,
        width: 1500,
        image: process.env.PUBLIC_URL + "/assets/images/icon/twiter.svg",
      },
      {
        name: i18n.t("twitterCoverImage"),
        height: 500,
        width: 1500,
        image: process.env.PUBLIC_URL + "/assets/images/icon/twiter.svg",
      },
      {
        name: i18n.t("twitterPosts"),
        height: 675,
        width: 1200,
        image: process.env.PUBLIC_URL + "/assets/images/icon/twiter.svg",
      },
      {
        name: i18n.t("linkedInBanner"),
        height: 768,
        width: 1400,
        image: process.env.PUBLIC_URL + "/assets/images/icon/linkdin.png",
      },
      {
        name: i18n.t("linkedInCoverImage"),
        height: 1584,
        width: 396,
        image: process.env.PUBLIC_URL + "/assets/images/icon/linkdin.png",
      },
      {
        name: i18n.t("linkedInPosts"),
        height: 628,
        width: 1200,
        image: process.env.PUBLIC_URL + "/assets/images/icon/linkdin.png",
      },
    ],
  },
  {
    category_name: i18n.t("marketing"),
    templates_size: [
      {
        name: i18n.t("flyers"),
        height: 2551,
        width: 3307,
        image: process.env.PUBLIC_URL + "/assets/images/icon/flyers.png",
      },
      {
        name: i18n.t("posters"),
        height: 3508,
        width: 4961,
        image: process.env.PUBLIC_URL + "/assets/images/icon/posters.png",
      },
      {
        name: i18n.t("digitalSignage"),
        height: 1920,
        width: 1080,
        image:
          process.env.PUBLIC_URL + "/assets/images/icon/digital_signage.png",
      },
      {
        name: i18n.t("printBanners"),
        height: 288,
        width: 72,
        image: process.env.PUBLIC_URL + "/assets/images/icon/print.png",
      },
      {
        name: i18n.t("labels"),
        height: 900,
        width: 600,
        image: process.env.PUBLIC_URL + "/assets/images/icon/labels.png",
      },
      {
        name: i18n.t("giftCertificates"),
        height: 1200,
        width: 1800,
        image: process.env.PUBLIC_URL + "/assets/images/icon/gift.png",
      },
      {
        name: i18n.t("onlineAds"),
        height: 600,
        width: 300,
        image: process.env.PUBLIC_URL + "/assets/images/icon/ads.png",
      },
      {
        name: i18n.t("tags"),
        height: 900,
        width: 600,
        image: process.env.PUBLIC_URL + "/assets/images/icon/tags.png",
      },
      {
        name: i18n.t("brochures"),
        height: 3508,
        width: 2480,
        image: process.env.PUBLIC_URL + "/assets/images/icon/brochures.png",
      },
      {
        name: i18n.t("storyBoards"),
        height: 2480,
        width: 3508,
        image: process.env.PUBLIC_URL + "/assets/images/icon/story_boards.png",
      },
    ],
  },
  {
    category_name: i18n.t("onlineAds"),
    templates_size: [
      {
        name: i18n.t("socialMediaAds"),
        height: 1080,
        width: 1080,
        image: process.env.PUBLIC_URL + "/assets/images/icon/ads.png",
      },
      {
        name: i18n.t("smallAds"),
        height: 200,
        width: 200,
        image: process.env.PUBLIC_URL + "/assets/images/icon/ads.png",
      },
      {
        name: i18n.t("mediumAds"),
        height: 250,
        width: 250,
        image: process.env.PUBLIC_URL + "/assets/images/icon/ads.png",
      },
      {
        name: i18n.t("largeAds"),
        height: 336,
        width: 280,
        image: process.env.PUBLIC_URL + "/assets/images/icon/ads.png",
      },
      {
        name: i18n.t("leaderboard"),
        height: 90,
        width: 728,
        image: process.env.PUBLIC_URL + "/assets/images/icon/leaderboard.png",
      },
    ],
  },
  {
    category_name: i18n.t("events"),
    templates_size: [
      {
        name: i18n.t("flyers"),
        height: 2551,
        width: 3307,
        image: process.env.PUBLIC_URL + "/assets/images/icon/flyers.png",
      },
      {
        name: i18n.t("posters"),
        height: 3508,
        width: 4961,
        image: process.env.PUBLIC_URL + "/assets/images/icon/posters.png",
      },
      {
        name: i18n.t("invitations"),
        height: 1200,
        width: 1800,
        image: process.env.PUBLIC_URL + "/assets/images/icon/invitations.png",
      },
      {
        name: i18n.t("tickets"),
        height: 600,
        width: 1800,
        image: process.env.PUBLIC_URL + "/assets/images/icon/tickets.png",
      },
      {
        name: i18n.t("schedules"),
        height: 1200,
        width: 1800,
        image: process.env.PUBLIC_URL + "/assets/images/icon/schedules.png",
      },
    ],
  },
  {
    category_name: i18n.t("personal"),
    templates_size: [
      {
        name: i18n.t("resume"),
        height: 3508,
        width: 2480,
        image: process.env.PUBLIC_URL + "/assets/images/icon/resume.png",
      },
      {
        name: i18n.t("invitation"),
        height: 1200,
        width: 1800,
        image: process.env.PUBLIC_URL + "/assets/images/icon/invitations.png",
      },
      {
        name: i18n.t("postCards"),
        height: 1800,
        width: 1200,
        image: process.env.PUBLIC_URL + "/assets/images/icon/cards.png",
      },
      {
        name: i18n.t("photoCollages"),
        height: 2000,
        width: 2000,
        image: process.env.PUBLIC_URL + "/assets/images/icon/collages.png",
      },
      {
        name: i18n.t("announcements"),
        height: 1200,
        width: 1800,
        image: process.env.PUBLIC_URL + "/assets/images/icon/announcements.png",
      },
      {
        name: i18n.t("cards"),
        height: 1200,
        width: 1800,
        image: process.env.PUBLIC_URL + "/assets/images/icon/cards.png",
      },
    ],
  },
  {
    category_name: i18n.t("webBanners"),
    templates_size: [
      {
        name: i18n.t("facebookCoverPhoto"),
        height: 315,
        width: 820,
        image: process.env.PUBLIC_URL + "/assets/images/icon/facebook.svg",
      },
      {
        name: i18n.t("youtubeChannelArt"),
        height: 1440,
        width: 2560,
        image: process.env.PUBLIC_URL + "/assets/images/icon/youtube.svg",
      },
      {
        name: i18n.t("zoomBackground"),
        height: 1080,
        width: 1920,
        image: process.env.PUBLIC_URL + "/assets/images/icon/zoom.png",
      },
      {
        name: i18n.t("emailHeaders"),
        height: 600,
        width: 600,
        image: process.env.PUBLIC_URL + "/assets/images/icon/email.png",
      },
      {
        name: i18n.t("blogHeader"),
        height: 312,
        width: 600,
        image: process.env.PUBLIC_URL + "/assets/images/icon/blog.png",
      },
      {
        name: i18n.t("socialMediaBanner"),
        height: 500,
        width: 1500,
        image: process.env.PUBLIC_URL + "/assets/images/icon/youtube.svg",
      },
    ],
  },
];

const SocialIconSectionold = {
  name: "Icons",
  Tab: (props) => (
    <SectionTab name={i18n.t("icons")} {...props}>
      <FaIcons icon="Icons" />
    </SectionTab>
  ),
  Panel: observer(({ store }) => {
    return <SocialMediaIconsPanel store={store} />;
  }),
};
const SocialIconSection = {
  // name: "image-clip",
  name: i18n.t("objects"),
  Tab: (props) => (
    <SectionTab name={i18n.t("objects")} {...props}>
      <img
        src={process.env.PUBLIC_URL + "/assets/images/icon/Object.svg"}
        alt=""
        style={{ height: "20px" }}
      ></img>
    </SectionTab>
  ),
  Panel: observer(({ store }) => {
    return <SocialMediaIconsPanel store={store} />;
  }),
};

const ShapesSection = {
  name: "image-clip",
  // name: "Shapes",
  Tab: (props) => null,
  // Tab: (props) => (
  //   <SectionTab name={i18n.t("shapes")} {...props}>
  //     <FaShapes icon="Shapes" />
  //   </SectionTab>
  // ),
  Panel: observer(({ store }) => {
    return <ShapePanel store={store} />;
  }),
};
const FancyShapesSection = {
  name: "image-clip",
  // name: "fancy-shape",
  // name: "Fancy Shapes",
  Tab: (props) => (
    <SectionTab name={i18n.t("shapesPolotno")} {...props}>
      <FaShapes icon="Shapes" style={{ height: "20px" }} />
    </SectionTab>
  ),
  Panel: observer(({ store }) => {
    return <FancyShapePanel store={store} />;
  }),
};

const ClipartSection = {
  name: "Cliparts",
  Tab: (props) => (
    <SectionTab name={i18n.t("cliparts")} {...props}>
      <FaBattleNet icon="Cliparts" />
    </SectionTab>
  ),
  Panel: observer(({ store }) => {
    return <ClipartsPanel store={store} />;
  }),
};
const StickersSection = {
  name: "Stickers",
  Tab: (props) => (
    <SectionTab name={i18n.t("stickers")} {...props}>
      <FaStickyNote icon="Stickers" />
    </SectionTab>
  ),
  Panel: observer(({ store }) => {
    return <StickersPanel store={store} />;
  }),
};
const UploadSection = {
  name: "Upload",
  Tab: (props) => (
    <SectionTab name={i18n.t("uploads")} {...props}>
      {/* <RiUploadCloud2Fill icon="Upload" /> */}
      <img
        src={process.env.PUBLIC_URL + "/assets/images/icon/Uploads.svg"}
        alt=""
        style={{ height: "20px" }}
      ></img>
    </SectionTab>
  ),
  Panel: observer(({ store }) => {
    return <UploadPanel store={store} />;
  }),
};
const ReplaceImageSection = {
  name: "Replace Image",
  Tab: (props) => null,
  // Tab: (props) => (
  //   <SectionTab name={i18n.t("replace_image")} {...props}>
  //     {/* <RiUploadCloud2Fill icon="Upload" /> */}
  //     <img
  //       src={process.env.PUBLIC_URL + "/assets/images/icon/replace-image.png"}
  //       alt=""
  //       style={{ height: "20px" }}
  //     ></img>
  //   </SectionTab>
  // ),

  Panel: observer(({ store }) => {
    return <UploadPanel store={store} isReplace={true} />;
  }),
};

const Editor = (props) => {
  const { type } = props;
  const height = useHeight();
  console.log("height:check===> ", height);
  const { id } = useParams();
  console.log("getTranslations: ", getTranslations());
  const user = getUserDataFromLocal();
  console.log("user: ", user);
  const location = useLocation();
  console.log("location: ", location);
  const { t, i18n } = useTranslation();
  // const { t } = useTranslation();
  const [submitBackClick, setSubmitBackClick] = useState(false);
  const [fontList, setFontList] = useState([]);
  const [submitPremiumClick, setSubmitPremiumClick] = useState(false);
  const [isNewDesign, setIsNewDesign] = useState(false);
  const [isPublish, setIsPublish] = useState(true);
  const [fillCategoryData, setFillCategoryData] = useState({});
  console.log("fillCategoryData: ", fillCategoryData);

  unstable_setAnimationsEnabled(true);
  const isEditMode = useMemo(() => {
    return type === "edit";
  }, [type]);
  const store = useMemo(
    () =>
      createStore({
        key: process.env.REACT_APP_POLOTNO_KEY,
        // key: "oSvZQiiL3UHw5L1ClB66",
        // showCredit: false,
      }),
    []
  );
  setTranslations(
    {
      sidePanel: {
        templates: t("templates"),
        searchTemplatesWithSameSize: t("searchTemplatesWithSameSizePolotno"),
        searchPlaceholder: t("searchPlaceholderPolotno"),
        otherFormats: t("otherFormatsPolotno"),
        noResults: t("noResultsPolotno"),
        error: t("errorPolotno"),
        text: t("textPolotno"),
        uploadFont: t("uploadFontPolotno"),
        myFonts: t("myFontsPolotno"),
        photos: t("photosPolotno"),
        videos: t("videosPolotno"),
        animations: t("animationsPolotno"),
        effects: t("effectsPolotno"),
        elements: t("elementsPolotno"),
        shapes: t("shapesPolotno"),
        lines: t("linesPolotno"),
        upload: t("uploadPolotno"),
        uploadImage: t("uploadImagePolotno"),
        uploadTip: t("uploadTipPolotno"),
        background: t("backgroundPolotno"),
        resize: t("resizePolotno"),
        layers: t("layersPolotno"),
        layersTip: t("layersTipPolotno"),
        noLayers: t("noLayersPolotno"),
        namePlaceholder: t("namePlaceholderPolotno"),
        useMagicResize: t("useMagicResizePolotno"),
        clipImage: t("clipImagePolotno"),
        width: t("widthPolotno"),
        height: t("heightPolotno"),
        magicResizeDescription: t("magicResizeDescriptionPolotno"),
        headerText: t("headerTextPolotno"),
        createHeader: t("createHeaderPolotno"),
        subHeaderText: t("subHeaderTextPolotno"),
        createSubHeader: t("createSubHeaderPolotno"),
        bodyText: t("bodyTextPolotno"),
        createBody: t("createBodyPolotno"),
        layerTypes: {
          image: t("imagePolotno"),
          text: t("TextPolotno"),
          svg: t("svgPolotno"),
          line: t("linePolotno"),
          figure: t("figurePolotno"),
          group: t("groupPolotno"),
        },
      },
      workspace: {
        noPages: t("noPagesPolotno"),
        addPage: t("addPagePolotno"),
        removePage: t("removePagePolotno"),
        duplicatePage: t("duplicatePagePolotno"),
        moveUp: t("moveUpPolotno"),
        moveDown: t("moveDownPolotno"),
      },
      toolbar: {
        duration: t("durationPolotno"),
        opacity: t("opacityPolotno"),
        effects: t("EffectsPolotno"),
        blur: t("blurPolotno"),
        textBackground: t("textBackgroundPolotno"),
        backgroundCornerRadius: t("backgroundCornerRadiusPolotno"),
        backgroundOpacity: t("backgroundOpacityPolotno"),
        backgroundPadding: t("backgroundPaddingPolotno"),
        brightness: t("brightnessPolotno"),
        sepia: t("sepiaPolotno"),
        grayscale: t("grayscalePolotno"),
        textStroke: t("textStrokePolotno"),
        shadow: t("shadowPolotno"),
        border: t("borderPolotno"),
        cornerRadius: t("cornerRadiusPolotno"),
        position: t("positionPolotno"),
        layering: t("layeringPolotno"),
        toForward: t("toForwardPolotno"),
        up: t("upPolotno"),
        down: t("downPolotno"),
        toBottom: t("toBottomPolotno"),
        alignLeft: t("alignLeftPolotno"),
        alignCenter: t("alignCenterPolotno"),
        alignRight: t("alignRightPolotno"),
        alignTop: t("alignTopPolotno"),
        alignMiddle: t("alignMiddlePolotno"),
        alignBottom: t("alignBottomPolotno"),
        flip: t("flipPolotno"),
        flipHorizontally: t("flipHorizontallyPolotno"),
        flipVertically: t("flipVerticallyPolotno"),
        fitToBackground: t("fitToBackgroundPolotno"),
        removeBackground:
          user?.is_subscribed == 1 ? t("RemoveBackgroundPolotno") : "",
        // removeBackground: "",
        removeBackgroundTitle: t("removeBackgroundTitlePolotno"),
        cancelRemoveBackground: t("cancelRemoveBackgroundPolotno"),
        confirmRemoveBackground: t("confirmRemoveBackgroundPolotno"),
        crop: t("cropPolotno"),
        cropDone: t("cropDonePolotno"),
        cropCancel: t("cropCancelPolotno"),
        clip: t("clipPolotno"),
        removeClip: t("removeClipPolotno"),
        removeMask: t("removeMaskPolotno"),
        transparency: t("transparencyPolotno"),
        lockedDescription: t("lockedDescriptionPolotno"),
        unlockedDescription: t("unlockedDescriptionPolotno"),
        removeElements: t("removeElementsPolotno"),
        duplicateElements: t("duplicateElementsPolotno"),
        download: t("downloadPolotno"),
        saveAsImage: t("saveAsImagePolotno"),
        saveAsPDF: t("saveAsPDFPolotno"),
        lineHeight: t("lineHeightPolotno"),
        letterSpacing: t("letterSpacingPolotno"),
        offsetX: t("offsetXPolotno"),
        offsetY: t("offsetYPolotno"),
        color: t("colorPolotno"),
        selectable: t("selectablePolotno"),
        draggable: t("draggablePolotno"),
        removable: t("removablePolotno"),
        resizable: t("resizablePolotno"),
        contentEditable: t("contentEditablePolotno"),
        styleEditable: t("styleEditablePolotno"),
        alwaysOnTop: t("alwaysOnTopPolotno"),
        showInExport: t("showInExportPolotno"),
        ungroupElements: t("ungroupElementsPolotno"),
        groupElements: t("groupElementsPolotno"),
        lineSize: t("lineSizePolotno"),
        fade: t("fadePolotno"),
        move: t("movePolotno"),
        zoom: t("zoomPolotno"),
        animate: t("animatePolotno"),
        rotate: t("rotatePolotno"),
        none: t("nonePolotno"),
        bounce: t("bouncePolotno"),
        blink: t("blinkPolotno"),
        spaceEvenly: t("spaceEvenlyPolotno"),
        horizontalDistribution: t("horizontalDistributionPolotno"),
        verticalDistribution: t("verticalDistributionPolotno"),
        strokeWidth: t("strokeWidthPolotno"),
        colorPicker: {
          solid: t("solidPolotno"),
          linear: t("linearPolotno"),
        },
      },
      error: {
        removeBackground: t("removeBackgroundPolotno"),
      },
      scale: {
        reset: t("resetPolotno"),
      },
    },
    {
      validate: true,
    }
  );
  // console.log("getTranslations", getTranslations());
  const [templateData, setTemplateData] = useState({});
  // const [lines, setLines] = useState([]);

  const [isPublishGoing, setIsPublishGoing] = useState(false);
  const [isSaveGoing, setIsSaveGoing] = useState(false);

  const [isInviteMemberModalOpen, setIsInviteMemberModalOpen] = useState(false);
  const [isAddTemplateModalOpen, setIsTemplateModalOpen] = useState(
    type === ""
  );
  console.log("isAddTemplateModalOpen: ", isAddTemplateModalOpen);
  const [isPublishTemplateModalOpen, setIsPublishTemplateModalOpen] = useState(
    type === ""
  );
  // const [font, setFonts] = useState([]);
  const [templateChange, setTemplateChange] = useState({});
  const [templateSizes, setTemplateSizes] = useState({
    height: "",
    width: "",
  });
  const isVideo = templateChange?.pages?.[0]?.children?.some(
    (item) => item?.type === "video"
  );
  console.log("templateSizes: ", templateSizes);
  const [isResizeModal, setIsResizeModal] = useState(false);

  // const isDrawing = useRef(false);

  // const addNewStar = () => {
  //   store.activePage.addElement({
  //     x: store.width - 200,
  //     y: store.height - 200,
  //     type: "star",
  //     radius: 100,
  //     fill: "green",
  //   });
  // };

  // useEffect(() => {
  //   getAllFonts({}).then((response) => {
  //     console.log("response: FONT", response);
  //     if (response.code === 200) {
  //       // setFonts(response?.data);
  //       response?.data?.map(async (item) => {
  //         addGlobalFont({
  //           fontFamily: item?.name,
  //           url: item?.image,
  //         });
  //         const fontFace = new FontFace(item.name, `url(${item.image})`);
  //         return await fontFace.load().then((loadedFace) => {
  //           document.fonts.add(loadedFace);
  //         });
  //       });
  //     }
  //   });
  // }, []);

  useEffect(() => {
    const fetchAndLoadFonts = async () => {
      try {
        const response = await getAllFonts({});
        console.log("response: FONT", response);
        if (response.code === 200 && response?.data?.length) {
          setFontList(response.data);
          const fontLoadingPromises = response.data.map(async (item) => {
            addGlobalFont({
              fontFamily: item?.name,
              url: item?.image,
            });

            const fontFace = new FontFace(item.name, `url(${item.image})`);
            const loadedFace = await fontFace.load();
            document.fonts.add(loadedFace);
          });

          // Wait for all fonts to be loaded
          await Promise.all(fontLoadingPromises);
          console.log("All fonts loaded successfully.");
        }
      } catch (error) {
        console.error("Error loading fonts:", error);
      }
    };

    fetchAndLoadFonts();
  }, []);

  setGoogleFonts([]);
  async function fetchVideoAsFile(videoUrl, fileName) {
    // Fetch the video data from the URL
    const response = await fetch(videoUrl);
    const blob = await response.blob();

    // Create a File object from the Blob
    const file = new File([blob], fileName, { type: blob.type });

    // Now you can upload the file to S3 or handle it as needed
    return file;
  }
  // const handleSaveEdit = useCallback(async (isnavigate) => {
  //   if (isEditMode) {
  //     const myPromise = new Promise(async (resolve, reject) => {
  //       const jsonData = store.toJSON();
  //       const fileName = generateRandomFileName();

  //       console.log(
  //         'templateData?.thumbnail?.split("/")[1]: ',
  //         templateData?.thumbnail?.split("/")[2]
  //       );
  //       await Promise.all([
  //         store
  //           .toBlob({ fileName })
  //           .then((response) => {
  //             response.name = isEditMode
  //               ? templateData?.thumbnail?.split("/")[2]
  //               : `${fileName}.${response.type.split("/")[1]}`;
  //             return uploadToS3(response, response.name, "thumbnails");
  //           })
  //           .then((response) => {
  //             console.log("Thumbnail S3 Key", response.key);
  //             return response.key;
  //           })
  //           .catch((error) => {
  //             console.error("Thumbnail upload failed:", error);
  //             throw error;
  //           }),

  //         uploadOrUpadateJSONToS3(
  //           jsonData,
  //           isEditMode
  //             ? templateData?.template?.split("/")[2]
  //             : `${fileName}.json`,
  //           "Templates"
  //         )
  //           .then((response) => {
  //             console.log("JSON S3 KEY", response.key);
  //             return response.key;
  //           })
  //           .catch((error) => {
  //             console.error("JSON upload failed:", error);
  //             throw error;
  //           }),
  //       ]);

  //       resolve({ message: "Updated" });
  //       showPromiseNotification(myPromise);
  //     });
  //   } else {
  //     setIsTemplateModalOpen(true);
  //   }
  // }, [isEditMode, store, templateData?.template, templateData?.thumbnail]);
  const handleSaveEdit = useCallback(async () => {
    if (isEditMode) {
      setIsSaveGoing((prev) => !prev);
      let newPromiseData = new Promise(async (resolve, reject) => {
        try {
          console.log("store.toJSON(): ", store.toJSON(), store);

          // if (location?.state == "invitedProject") {
          //   const watermarkIds = [];
          //   await store.toJSON().pages.forEach((page) => {
          //     const filteredChildren = page
          //       // .toJSON()
          //       .children.filter((child) => child.name == "watermark");
          //     console.log("filteredChildren: ", filteredChildren);

          //     watermarkIds.push(...filteredChildren?.map(item => item.id));
          //   });
          //   await store.deleteElements(watermarkIds);
          // }
          const watermarkIds = [];
          await store.toJSON().pages.forEach((page) => {
            const filteredChildren = page.children // .toJSON()
              .filter((child) => child.name == "watermark");
            console.log("filteredChildren: ", filteredChildren);

            watermarkIds.push(...filteredChildren?.map((item) => item.id));
          });
          await store.deleteElements(watermarkIds);
          const jsonData = store.toJSON();
          const fileNameThumbnail = generateRandomFileName();
          const fileNameMainImage = generateRandomFileName();

          console.log(
            'templateData?.thumbnail?.split("/")[2]: ',
            templateData?.thumbnail?.split("/")[2]
          );

          console.log("store.toJSON(): ", store.toJSON(), store);
          const [thumbnailKey, jsonKey] = await Promise.all([
            store
              // .saveAsImage({ fileName, pixelRatio: 0.2, mimeType: "image/webp" })
              .toBlob({
                fileNameThumbnail,
                pixelRatio: 0.4,
                mimeType: "image/webp",
              })
              .then(async (response) => {
                // response.name = isEditMode
                //   ? templateData?.thumbnail?.split("/")[2]
                //   : `${fileName}.${response.type.split("/")[1]}`;
                response.name = `${fileNameThumbnail}.${response.type.split("/")[1]
                  }`;
                console.log(
                  "templateData123: ",
                  templateData,
                  response,
                  response.name
                );
                return uploadToS3(response, response.name, "thumbnails").then(
                  (response) => {
                    console.log(
                      "Thumbnail S3 Key 7777",
                      response,
                      response.key
                    );
                    return response.key;
                  }
                );
              })
              .catch((error) => {
                console.error("Thumbnail upload failed:", error);
                reject(error); // Reject the promise if there's an error
              }),

            uploadOrUpadateJSONToS3(
              jsonData,
              isEditMode
                ? templateData?.template?.split("/")[2]
                : `${fileNameMainImage}.json`,
              "Templates"
            )
              .then((response) => {
                console.log("JSON S3 KEY", response.key);
                return response.key;
              })
              .catch((error) => {
                console.error("JSON upload failed:", error);
                reject(error); // Reject the promise if there's an error
              }),
          ]);
          const [main_thumbnailKey] = await Promise.all([
            store
              .toBlob({
                fileNameMainImage,
                pixelRatio: 1,
                mimeType: "image/webp",
              })
              .then(async (response) => {
                // response.name = isEditMode
                //   ? templateData?.main_thumbnail?.split("/")[2]
                //   : `${fileName}.${response.type.split("/")[1]}`;
                response.name = `${fileNameMainImage}.${response.type.split("/")[1]
                  }`;
                console.log(
                  "templateData123 Main: ",
                  templateData,
                  response,
                  response.name
                );
                return uploadToS3(response, response.name, "thumbnails").then(
                  (response) => {
                    console.log("Thumbnail S3 Key Main 777", response.key);
                    return response.key;
                  }
                );
              })
              .catch((error) => {
                console.error("Thumbnail upload failed:", error);
                reject(error); // Reject the promise if there's an error
              }),

            // uploadOrUpadateJSONToS3(
            //   jsonData,
            //   isEditMode
            //     ? templateData?.template?.split("/")[2]
            //     : `${fileName}.json`,
            //   "Templates"
            // )
            //   .then((response) => {
            //     console.log("JSON S3 KEY Main", response.key);
            //     return response.key;
            //   })
            //   .catch((error) => {
            //     console.error("JSON upload failed:", error);
            //     reject(error); // Reject the promise if there's an error
            //   }),
          ]);
          if (isVideo == 1) {
            let newFonts = await store
              .toJSON()
              ?.pages[0].children.filter(
                (res) => res?.type === "text" && res.fontFamily?.length > 0
              )
              // .map((res) => {res.fontFamily});
              .map((res) => {
                let fonUrl = fontList.find((font) => {
                  return res.fontFamily === font.name; // Ensure the comparison is returned
                });
                console.log(" newStore fonUrl: ", fonUrl);
                if (fonUrl && fonUrl?.image) {
                  store.addFont({
                    fontFamily: res.fontFamily,
                    url: fonUrl ? fonUrl?.image : "",
                  });
                }
              });
            const jsonData = store.toJSON();
            const req = await fetch(
              `https://api.polotno.com/api/renders?KEY=${process.env.REACT_APP_POLOTNO_KEY}`,
              {
                method: "POST",
                headers: {
                  // it is important to set a json content type
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  // polotno json from store.toJSON()
                  design: jsonData,
                  format: "mp4",
                  outputFormat: "url",
                  // here you can pass other render options
                  pixelRatio: 0.3,
                  // see bellow for full details of options
                }),
              }
            );

            const { id: jonId } = await req.json();

            if (jonId) {
              for (let i = 0; i < 100; i++) {
                const req = await fetch(
                  "https://api.polotno.com/api/renders/" +
                  jonId +
                  "?KEY=" +
                  process.env.REACT_APP_POLOTNO_KEY
                );
                const job = await req.json();
                console.log("job: status===========>", job);
                if (job.status === "error") {
                  alert("Error: " + job.error);
                  break;
                }
                // if (job.status === "progress") {
                //   setProgress(job.progress);

                // }
                if (job.status === "done") {
                  const url = job.output;

                  // downloadFile(url, "export." + "mp4");
                  // setProgress(0);
                  // setIsSubmitting(false)

                  await fetchVideoAsFile(url, fileNameMainImage)
                    .then(async (file) => {
                      console.log("File created:", file);
                      await uploadToS3(
                        file,
                        `${generateRandomFileName()}.mp4`,
                        "template_vidoes"
                      ).then(async (response) => {
                        await editTemplate({
                          template_id: templateData.id,
                          preview_video: response.key,
                          is_video: isVideo,
                        }).then((response) => {
                          console.log("response: ", response);
                          // if (response.code === 200) {

                          // } else {
                          // }
                        });
                      });
                    })
                    .catch((error) => {
                      console.error("Error fetching video:", error);
                    });
                  break;
                }
                // wait a bit
                await new Promise((r) => setTimeout(r, 1000));
              }
            }

            // await mp4Download({
            //   template_data: jsonData,
            //   video_quality: 0.1,
            // }).then(async (response) => {
            //   console.log("videoUrl response: ", response);
            //   if (response.code === 200) {
            //     // Create a hidden iframe
            //     const videoUrl = response.data; // Assuming response.data contains the video URL
            //     console.log("videoUrl: ", videoUrl);
            //     await fetchVideoAsFile(videoUrl, fileNameMainImage)
            //       .then(async (file) => {
            //         console.log("File created:", file);
            //         await uploadToS3(
            //           file,
            //           `${generateRandomFileName()}.mp4`,
            //           "template_vidoes"
            //         ).then(async (response) => {
            //           await editTemplate({
            //             template_id: templateData.id,
            //             preview_video: response.key,
            //             is_video: isVideo,
            //           }).then((response) => {
            //             console.log("response: ", response);
            //             // if (response.code === 200) {

            //             // } else {
            //             // }
            //           });
            //         });
            //       })
            //       .catch((error) => {
            //         console.error("Error fetching video:", error);
            //       });
            //   } else {
            //     reject({ message: "Failed to convert into MP4" });
            //     return;
            //   }
            // });
          }
          await editTemplate({
            template_id: templateData.id,
            category_id: fillCategoryData.category_id,
            subcategory_id: fillCategoryData.subcategory_id,
            thumbnail: thumbnailKey,
            main_thumbnail: main_thumbnailKey,
          }).then((response) => {
            console.log("response: ", response);
            // if (response.code === 200) {

            // } else {
            // }
          });
          setIsSaveGoing((prev) => !prev);
          resolve({ message: "Updated", thumbnailKey, jsonKey });
        } catch (error) {
          reject(error); // Reject the promise if there's an error
        }
      });
      showPromiseNotification(newPromiseData);
      return newPromiseData;
    } else {
      setIsTemplateModalOpen(true);
      return Promise.resolve({ message: "Modal opened" }); // Return a resolved promise
    }
  }, [
    fillCategoryData.category_id,
    fillCategoryData.subcategory_id,
    isEditMode,
    isVideo,
    store,
    templateData,
  ]);

  const MyToolbar = ({ store }) => {
    return (
      // <div className="font-family-input">
      <Toolbar store={store} />
      // </div>
    );
  };

  // const FontSection = {
  //   name: "Fonts",
  //   Tab: (props) => (
  //     <SectionTab name="Fonts" {...props}>
  //       <FaFont icon="Fonts" />
  //     </SectionTab>
  //   ),
  //   Panel: observer(({ store }) => {
  //     return <CustomFontPanel store={store} font={font} />;
  //   }),
  // };

  const ResizePanel = observer(({ store }) => {
    return (
      <div className="custom-size-btn-editor h-100 overflow-auto pe-3">
        <div className="">
          <NumericInput
            min={0}
            className="mb-2  w-100 d-flex"
            value={templateSizes.width}
            onChange={(e) => {
              setTemplateSizes((prev) => {
                return { ...prev, width: Number(e.target.value) };
              });
            }}
          />
          <NumericInput
            min={0}
            className="mb-2  w-100 d-flex"
            value={templateSizes.height}
            onChange={(e) => {
              setTemplateSizes((prev) => {
                return { ...prev, height: Number(e.target.value) };
              });
            }}
          />

          <Button
            className="w-100 "
            onClick={() => {
              console.log("templateSizes.width: ", templateSizes.width);
              store.setSize(templateSizes.width, templateSizes.height, true);
            }}
          >
            {i18n.t("applySize")}
          </Button>
        </div>

        {FIX_SIZES.map((category, index) => (
          <div key={index}>
            <p className="my-3">{category.category_name}</p>
            <ul>
              {category.templates_size.map((template, subIndex) => (
                <Button
                  key={subIndex}
                  className="mb-2 w-100"
                  onClick={() => {
                    setTemplateSizes({
                      width: template.width,
                      height: template.height,
                    });
                    store.setSize(template.width, template.height, true);
                  }}
                >
                  {template.name} - {template.width} X {template.height}
                </Button>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  });

  const ResizeSection = DEFAULT_SECTIONS.find(
    (section) => section.name === "size"
  );
  console.log("DEFAULT_SECTIONS: ", DEFAULT_SECTIONS);

  // overwrite its panel component
  ResizeSection.Panel = ResizePanel;
  const filteredDefaultSections = DEFAULT_SECTIONS.filter(
    (section) =>
      // section.name !== "elements" &&
      section.name != "size" &&
      section.name != "upload" &&
      section.name != "templates"
  );

  let updatedSections = [
    // FontSection,
    SocialIconSection,
    ShapesSection,
    // ClipartSection,
    // StickersSection,
    UploadSection,
    ReplaceImageSection,
    VideosSection,
    FancyShapesSection,
    QrSection,
    // QuotesSection,
    ...filteredDefaultSections,
  ];
  // updatedSections.splice(8, 0, FancyShapesSection)
  console.log("updatedSections: ", updatedSections);

  const sections = updatedSections.map((section) => {
    if (section.name === "elements") {
      return {
        ...section,
        Tab: (props) => (
          <SectionTab name={i18n.t("basic")} {...props}>
            <FaShapes icon="Shapes" style={{ height: "20px" }} />
          </SectionTab>
        ),
      };
    }
    if (section.name === "photos") {
      return {
        ...section,
        Tab: (props) => (
          <SectionTab name={i18n.t("photosPolotno")} {...props}>
            {/* <RiUploadCloud2Fill icon="Upload" /> */}
            <img
              src={process.env.PUBLIC_URL + "/assets/images/icon/Images.svg"}
              alt=""
              style={{ height: "20px" }}
            ></img>
          </SectionTab>
        ),
      };
    }
    if (section.name === "background") {
      return {
        ...section,
        Tab: (props) => (
          <SectionTab name={i18n.t("bkPolotno")} {...props}>
            {/* <RiUploadCloud2Fill icon="Upload" /> */}
            <img
              src={process.env.PUBLIC_URL + "/assets/images/icon/BG.svg"}
              alt=""
              style={{ height: "20px" }}
            ></img>
          </SectionTab>
        ),
      };
    }
    if (section.name === "text") {
      return {
        ...section,
        Tab: (props) => (
          <SectionTab name={i18n.t("TextPolotno")} {...props}>
            {/* <RiUploadCloud2Fill icon="Upload" /> */}
            <img
              src={process.env.PUBLIC_URL + "/assets/images/icon/text.svg"}
              alt=""
              style={{ height: "20px" }}
            ></img>
          </SectionTab>
        ),
      };
    }
    if (section.name === "videos") {
      return {
        ...section,
        Tab: (props) => (
          <SectionTab name={i18n.t("videosPolotno")} {...props}>
            {/* <RiUploadCloud2Fill icon="Upload" /> */}
            <img
              src={process.env.PUBLIC_URL + "/assets/images/icon/Videos.svg"}
              alt=""
              style={{ height: "20px" }}
            ></img>
          </SectionTab>
        ),
      };
    }
    return section;
  });
  const shouldAddWatermark = user?.is_subscribed === 0;

  const [storeJson, setStoreJson] = useState({});
  const handleWatermark = useCallback(async () => {
    if (type === "add") {
      async function HandleWatermark() {
        console.log("HandleWatermark:123 ", store.toJSON()?.pages[0]?.children);
        await store.history.ignore(async () => {
          await addWatermark(store);
        });
      }
      HandleWatermark();
      console.log("HandleWatermark: ");
    }
  }, [store, type]);
  useEffect(() => {
    console.log(
      "aaaaa",
      shouldAddWatermark &&
      (!location.state || location?.state != "invitedProject") &&
      !isPublishGoing &&
      !isSaveGoing &&
      templateChange?.pages?.length > 0 &&
      !templateChange?.pages?.[0]?.children?.some(
        (item) => item?.name === "watermark"
      ),
      shouldAddWatermark
    );

    if (
      shouldAddWatermark &&
      (!location.state || location?.state != "invitedProject") &&
      !isPublishGoing &&
      !isSaveGoing &&
      templateChange?.pages?.length > 0 &&
      !templateChange?.pages?.[0]?.children?.some(
        (item) => item?.name === "watermark"
      )
    ) {
      async function HandleWatermark() {
        await store.history.ignore(async () => {
          addWatermark(store);
        });
      }
      HandleWatermark();
    }
  }, [
    isPublishGoing,
    isSaveGoing,
    location.state,
    shouldAddWatermark,
    store,
    templateChange?.pages,
  ]);
  // useEffect(() => {
  //   lines?.forEach((item) => {
  //     store.activePage?.addElement({
  //       type: "pen",
  //       stroke: "black",
  //       strokeWidth: 2,
  //       points: item.points,
  //     });
  //   });
  // }, [lines, store.activePage]);

  // const handleMouseDown = (e) => {
  //   isDrawing.current = true;
  //   // const rect = e.currentTarget.getBoundingClientRect();
  //   const pos = {
  //     clientX: e.clientX,
  //     clientY: e.clientY,
  //   };
  //   setLines([...lines, { points: [pos.clientX, pos.clientY] }]);
  // };

  // const handleMouseMove = (e) => {
  //   if (!isDrawing.current) {
  //     return;
  //   }

  //   const point = {
  //     clientX: e.clientX,
  //     clientY: e.clientY,
  //   };
  //   const lastLine = lines[lines.length - 1];
  //   console.log("lastLine: ", lastLine);
  //   lastLine.points = lastLine.points.concat([point.clientX, point.clientY]);
  //   console.log("lastLine:==> ", lastLine);
  //   [...lines.slice(0, -1), lastLine]?.forEach((item) => {
  //     store.activePage?.addElement({
  //       type: "pen",
  //       stroke: "black",
  //       strokeWidth: 2,
  //       points: item.points,
  //     });
  //   });
  //   // setLines([...lines.slice(0, -1), lastLine]);
  // };

  // const handleMouseUp = () => {
  //   isDrawing.current = false;
  // };
  useEffect(() => {
    if ((type === "edit" || type === "copy") && id) {
      getTemplate({ template_id: id }).then((_template) => {
        console.log("_template: ", _template);
        setTemplateData(_template?.data[0]);
      });
    } else {
      store.addPage();
      // change on water mark
      // handleWatermark()
      // async function HandleWatermark() {
      //   console.log('HandleWatermark:123 ');
      //   await store.history.ignore(() => {
      //     if (shouldAddWatermark) {
      //       addWatermark(store);
      //       console.log('store: ', store.toJSON());
      //     }
      //   });
      // }
      // HandleWatermark();
      // console.log('HandleWatermark: ');
    }
  }, [id, store, type]);

  console.log(
    "storeJson: ",
    JSON.stringify(storeJson) != JSON.stringify(store.toJSON()),
    store.toJSON(),
    storeJson
  );

  const addWatermarkOld = (store) => {
    const page = store.pages[0];
    const imageUrl = process.env.PUBLIC_URL + "/assets/images/watermark.png";

    // Create a new image element to get its natural dimensions
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      // Get natural dimensions
      const watermarkWidth = img.naturalWidth;
      const watermarkHeight = img.naturalHeight;

      // Optional: Scale dimensions if needed
      const scaleFactor = 0.3; // Adjust this scale factor as needed
      const scaledWidth = watermarkWidth * scaleFactor;
      const scaledHeight = watermarkHeight * scaleFactor;

      // Calculate positions to center the watermark
      // const xPosition = (store.width - scaledWidth) / 2;
      // const yPosition = (store.height - scaledHeight) / 2;
      const margin = 30;
      // Calculate positions to align with the left bottom corner
      const xPosition = margin; // Align with the left edge
      const yPosition = store.height - scaledHeight - margin; // Align with the bottom edge

      page.addElement({
        type: "image",
        src: imageUrl,
        opacity: 1,
        height: scaledHeight, // Use the scaled height
        width: scaledWidth, // Use the scaled width
        selectable: false,
        alwaysOnTop: true,
        showInExport: true,
        x: xPosition, // Center horizontally
        y: yPosition, // Center vertically
      });
    };

    return img; // Returning the image element for potential debugging
  };
  // const addWatermark = (store) => {
  //   const page = store.pages[0];
  //   const imageUrl = process.env.PUBLIC_URL + "/assets/images/watermark.png";

  //   // Function to draw an image with rounded corners and adjust brightness
  //   const drawImageWithRoundedCorners = (
  //     img,
  //     brightness,
  //     borderRadius,
  //     callback
  //   ) => {
  //     const canvas = document.createElement("canvas");
  //     const ctx = canvas.getContext("2d");
  //     canvas.width = img.width;
  //     canvas.height = img.height;

  //     // Draw rounded rectangle
  //     ctx.save();
  //     ctx.beginPath();
  //     ctx.moveTo(borderRadius, 0);
  //     ctx.lineTo(canvas.width - borderRadius, 0);
  //     ctx.quadraticCurveTo(canvas.width, 0, canvas.width, borderRadius);
  //     ctx.lineTo(canvas.width, canvas.height - borderRadius);
  //     ctx.quadraticCurveTo(
  //       canvas.width,
  //       canvas.height,
  //       canvas.width - borderRadius,
  //       canvas.height
  //     );
  //     ctx.lineTo(borderRadius, canvas.height);
  //     ctx.quadraticCurveTo(0, canvas.height, 0, canvas.height - borderRadius);
  //     ctx.lineTo(0, borderRadius);
  //     ctx.quadraticCurveTo(0, 0, borderRadius, 0);
  //     ctx.closePath();
  //     ctx.clip();

  //     // Draw image
  //     ctx.drawImage(img, 0, 0);

  //     // Adjust brightness
  //     ctx.globalCompositeOperation = "source-over";
  //     ctx.fillStyle = `rgba(255, 255, 255, ${brightness})`;
  //     ctx.fillRect(0, 0, canvas.width, canvas.height);

  //     ctx.restore();

  //     callback(canvas.toDataURL());
  //   };

  //   // Create a new image element to get its natural dimensions
  //   const img = new Image();
  //   img.src = imageUrl;

  //   img.onload = () => {
  //     // Define parameters
  //     const borderRadius = 25; // Border radius in pixels
  //     const brightness = 0; // Brightness adjustment factor

  //     // Draw the image with rounded corners and adjusted brightness
  //     drawImageWithRoundedCorners(img, brightness, borderRadius, (processedImageUrl) => {
  //       console.log('processedImageUrl: ', processedImageUrl);
  //       // Calculate the height as 5% of the store's height
  //       const newHeight = store.height == store.width ? store.height - 300 : store.height > store.width ? store.width : store.height
  //       console.log('store.height > store.width: ', store.height / store.width);
  //       const watermarkHeight = newHeight * 0.1; // 5% of store height
  //       const scaleFactor = watermarkHeight / img.naturalHeight;
  //       const watermarkWidth = img.naturalWidth * scaleFactor;

  //       // Define a margin
  //       const margin = 10;

  //       // Calculate positions to align with the left bottom corner
  //       const xPosition = margin; // Align with the left edge plus margin
  //       const yPosition = store.height - watermarkHeight - margin; // Align with the bottom edge plus margin
  //       page.addElement({
  //         name: "watermark",
  //         type: "image",
  //         src: processedImageUrl,
  //         opacity: 1,
  //         height: watermarkHeight, // Use the calculated height (5% of store height)
  //         width: watermarkWidth, // Use the scaled width
  //         selectable: false,
  //         alwaysOnTop: true,
  //         showInExport: true,
  //         x: xPosition, // Align horizontally with margin
  //         y: yPosition, // Align vertically with margin
  //       });
  //     }
  //     );
  //   };

  //   return img; // Returning the image element for potential debugging
  // };

  const addWatermark = (store) => {
    const imageUrl = process.env.PUBLIC_URL + "/assets/images/watermark.png";

    // Function to draw an image with rounded corners and adjust brightness
    const drawImageWithRoundedCorners = (
      img,
      brightness,
      borderRadius,
      callback
    ) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw rounded rectangle
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(borderRadius, 0);
      ctx.lineTo(canvas.width - borderRadius, 0);
      ctx.quadraticCurveTo(canvas.width, 0, canvas.width, borderRadius);
      ctx.lineTo(canvas.width, canvas.height - borderRadius);
      ctx.quadraticCurveTo(
        canvas.width,
        canvas.height,
        canvas.width - borderRadius,
        canvas.height
      );
      ctx.lineTo(borderRadius, canvas.height);
      ctx.quadraticCurveTo(0, canvas.height, 0, canvas.height - borderRadius);
      ctx.lineTo(0, borderRadius);
      ctx.quadraticCurveTo(0, 0, borderRadius, 0);
      ctx.closePath();
      ctx.clip();

      // Draw image
      ctx.drawImage(img, 0, 0);

      // Adjust brightness
      ctx.globalCompositeOperation = "source-over";
      ctx.fillStyle = `rgba(255, 255, 255, ${brightness})`;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.restore();

      callback(canvas.toDataURL());
    };

    // Create a new image element to get its natural dimensions
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      // Define parameters
      const borderRadius = 25; // Border radius in pixels
      const brightness = 0; // Brightness adjustment factor

      // Loop through all pages in the store
      store.pages.forEach((page) => {
        // Draw the image with rounded corners and adjusted brightness
        drawImageWithRoundedCorners(
          img,
          brightness,
          borderRadius,
          (processedImageUrl) => {
            // Calculate the height as 5% of the store's height
            const newHeight =
              store.height == store.width
                ? store.height - 300
                : store.height > store.width
                  ? store.width
                  : store.height;
            const watermarkHeight = newHeight * 0.1; // 5% of store height
            const scaleFactor = watermarkHeight / img.naturalHeight;
            const watermarkWidth = img.naturalWidth * scaleFactor;

            // Define a margin
            const margin = 10;

            // Calculate positions to align with the left bottom corner
            const xPosition = margin; // Align with the left edge plus margin
            const yPosition = store.height - watermarkHeight - margin; // Align with the bottom edge plus margin

            // Add the watermark to the current page
            page.addElement({
              name: "watermark",
              type: "image",
              src: processedImageUrl,
              opacity: 1,
              height: watermarkHeight, // Use the calculated height (5% of store height)
              width: watermarkWidth, // Use the scaled width
              selectable: false,
              alwaysOnTop: true,
              showInExport: true,
              x: xPosition, // Align horizontally with margin
              y: yPosition, // Align vertically with margin
            });
          }
        );
      });
    };

    return img; // Returning the image element for potential debugging
  };

  useEffect(() => {
    if (templateData?.id && templateData?.template?.length) {
      fetchJSONFromS3(templateData.template).then(async (response) => {
        setStoreJson({ ...response, custom: undefined });
        setTemplateSizes({ height: response?.height, width: response?.width });
        // store.waitLoading()
        // const shouldAddWatermark = user?.is_subscribed === 0;
        store.loadJSON(response, false);
        // await store.history.ignore(() => {
        //   if (shouldAddWatermark) {
        //     watermark = addWatermark(store);
        //   }
        // });
      });
      // fetchJSONFromS3(templateData?.thumbnail);
    }
    // else {
    //   store.history.ignore(() => {
    //     console.log("cheeeeeeeeeeee", shouldAddWatermark);
    //     if (shouldAddWatermark) {
    //       addWatermark(store);
    //     }
    //   });
    // }
  }, [
    shouldAddWatermark,
    store,
    templateData?.id,
    templateData.template,
    templateData.thumbnail,
    user.is_subscribed,
  ]);

  // store.on("change", async (data) => {
  //   console.log("data: ====>", data);
  //   setTemplateChange(data);
  // });
  React.useEffect(() => {
    const off = store.on("change", async (data) => {
      console.log("data: ====>", data);
      setTemplateChange(data);
    });

    return () => {
      off();
    };
  }, []);
  useEffect(() => {
    if (type == "add") {
      setIsResizeModal(true);
    }
  }, [type]);
  // const workspaceRef = useRef(null);

  // useEffect(() => {
  //   const handleContextMenu = (event) => {
  //     event.preventDefault();
  //   };

  //   const attachEventListener = () => {
  //     const canvasElement = workspaceRef.current?.querySelector('canvas');
  //     if (canvasElement) {
  //       canvasElement.addEventListener('contextmenu', handleContextMenu);
  //     }
  //   };

  //   // Attach the event listener after a delay to ensure the canvas is rendered
  //   const timeoutId = setTimeout(attachEventListener, 1000);

  //   // Clean up event listener on unmount
  //   return () => {
  //     clearTimeout(timeoutId);
  //     const canvasElement = workspaceRef.current?.querySelector('canvas');
  //     if (canvasElement) {
  //       canvasElement.removeEventListener('contextmenu', handleContextMenu);
  //     }
  //   };
  // }, []);
  // const [isDirty, setIsDirty] = useState(false); // Track if there are unsaved changes
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     if (isDirty) {
  //       const confirmationMessage = 'You have unsaved changes, do you really want to leave?';
  //       event.returnValue = confirmationMessage; // Standard for most browsers
  //       return confirmationMessage; // Required for some browsers
  //     }
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [isDirty]);
  // useEffect(() => {
  //   console.log("response147", store.toJSON(), storeJson, isDirty);
  //   if (store.toJSON() != storeJson) {
  //     setIsDirty(true);
  //   } else {
  //     setIsDirty(false);
  //   }
  // }, [isDirty, store, storeJson])

  // working

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  // working
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      console.log(JSON.stringify(storeJson) != JSON.stringify(store.toJSON()));

      if (JSON.stringify(storeJson) != JSON.stringify(store.toJSON())) {
        console.log("event.returnValue: ", event);
        const confirmationMessage =
          "You have unsaved changes, do you really want to leave?";
        event.returnValue = confirmationMessage; // Standard for most browsers
        return confirmationMessage; // Required for some browsers
      }
    };

    const handleVisibilityChange = (event) => {
      console.log("event.returnValue: ", event);
      const confirmationMessage =
        "You have unsaved changes, do you really want to leave?";
      alert(confirmationMessage); // Standard for most browsers
      return confirmationMessage;
      // if (document.visibilityState === 'hidden') {
      //   const confirmationMessage = 'You have unsaved changes, do you really want to leave12?';
      //   alert(confirmationMessage); // This alert serves as a warning when switching tabs
      // }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("blur", handleBeforeUnload);
    // document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      // document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [store, storeJson]);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     console.log('event: ', event);
  //     event.preventDefault();
  //     event.returnValue = ''; // Some browsers require returnValue to be set.
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   window.addEventListener("blur", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //     window.addEventListener("blur", handleBeforeUnload);
  //   };
  // }, []);

  const ReplaceImageButtonOn = observer(({ store, element, elements }) => {
    return (
      <button
        className="bp5-button bp5-minimal"
        onClick={() => {
          store.openSidePanel('Replace Image')
        }}
      >
        Replace Image
      </button>
    );
  });
  return (
    <>
      <div
        id="container"
        className="d-flex flex-column overflow-hidden "
        style={{ height: height + "px" }}
      >
        <Header
          store={store}
          templateChange={templateChange}
          templateData={templateData}
          setIsInviteMemberModalOpen={setIsInviteMemberModalOpen}
          handleSaveEdit={handleSaveEdit}
          setSubmitBackClick={setSubmitBackClick}
          setSubmitPremiumClick={setSubmitPremiumClick}
          setIsPublishTemplateModalOpen={setIsPublishTemplateModalOpen}
          type={type}
          setIsResizeModal={setIsResizeModal}
          setIsPublish={setIsPublish}
          isPublish={isPublish}
          setIsTemplateModalOpen={setIsTemplateModalOpen}
          setIsNewDesign={setIsNewDesign}
          setTemplateData={setTemplateData}
          fontList={fontList}
        />
        {isInviteMemberModalOpen && (
          <InviteMembers
            isInviteMemberModalOpen={isInviteMemberModalOpen}
            setIsInviteMemberModalOpen={setIsInviteMemberModalOpen}
            id={id}
          />
        )}

        {isAddTemplateModalOpen && (
          <AddTemplate
            store={store}
            isAddTemplateModalOpen={isAddTemplateModalOpen}
            submitBackClick={submitBackClick}
            submitPremiumClick={submitPremiumClick}
            setIsTemplateModalOpen={setIsTemplateModalOpen}
            type={type}
            templateData={templateData}
            setTemplateData={setTemplateData}
            isNewDesign={isNewDesign}
            templateChange={templateChange}
            fillCategoryData={fillCategoryData}
            setIsSaveGoing={setIsSaveGoing}
            fontList={fontList}
          />
        )}
        {isPublishTemplateModalOpen && (
          <PublishTemplate
            store={store}
            isPublishTemplateModalOpen={isPublishTemplateModalOpen}
            submitBackClick={submitBackClick}
            setIsPublishTemplateModalOpen={setIsPublishTemplateModalOpen}
            templateData={templateData}
            setIsPublish={setIsPublish}
            setIsPublishGoing={setIsPublishGoing}
          />
        )}

        <ResizeModal
          setIsResizeModal={setIsResizeModal}
          isResizeModal={isResizeModal}
          FIX_SIZES={FIX_SIZES}
          setTemplateSizes={setTemplateSizes}
          store={store}
          setFillCategoryData={setFillCategoryData}
        />
        <div style={{ height: "calc(100% - 57px)" }}>
          <PolotnoContainer
            className="polotno-app-container"
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "100%",
              flexGrow: 1,
              // overflow: "auto",
            }}
          >
            <SidePanelWrap classname="dsdsdsds">
              <SidePanel
                store={store}
                sections={sections}
                defaultSection="custom"
              />
            </SidePanelWrap>
            <WorkspaceWrap>
              {/* <MyToolbar store={store} downloadButtonEnabled /> */}
              <Toolbar store={store} components={{
                ImageReplaceButton: ReplaceImageButtonOn,
                ImageRemoveBackground: () => null
              }} />
              <Workspace
                store={store}
                // ref={workspaceRef}
                components={{
                  NoPages: ({ store }) => (
                    <>
                      <CustomLoader />
                    </>
                  ),
                }}
              />
              <ZoomButtons store={store} />
              <PagesTimeline store={store} />
            </WorkspaceWrap>
          </PolotnoContainer>
        </div>
      </div>
    </>
  );
};

export default Editor;
